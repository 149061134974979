import { PaymentMethod, PaymentNomination, PaymentStatus } from "@/constants/PaymentConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export class PaymentDTO {
    id!: number;
    createDate!: Date;
    updateDate!: Date | null;
    amount!: number;
    paymentNomination!: PaymentNomination;
    paymentStatus!: PaymentStatus;
    scheduledPaymentDate!: Date;
    actualPaymentDate!: Date | null;
    invoiceNumber!: string | null;
    invoiceDocument!: FileMetaDTO | null;
    payerCompanyId!: number | null;
    payerName!: string;
    monitored!: boolean;
    paymentMethod!: PaymentMethod | null;
    paymentAccount!: string | null;

    constructor(init?: Partial<PaymentDTO>) {
        Object.assign(this, init);
    }
}
